import React from 'react';
import Link from 'components/ui/link';
import Article, { SubTitle } from 'components/help/helpArticle';

const Content = () => {
  return (
    <div>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Personal data protection and privacy are the core values of LiveSession. Below we’ve
        described everything you need to know about the{' '}
        <strong>CCPA - California Consumer Privacy Act</strong>. If you don’t have time to read
        through complex legal documents, this short summary should be enough.
      </div>
      <p>
        <strong>
          Disclaimer: LiveSession will never sell your, your employees’ or your website visitors’
          personal data to anyone.
        </strong>
      </p>
      <p>
        We’re fully <Link href="/help/gdpr/">GDPR compliant</Link>, which is why the CCPA is a
        natural next step for us. As GDPR is a distinct European regulation, we prepared this short
        guide to CCPA for you:
      </p>
      <SubTitle>What is CCPA?</SubTitle>
      <p>
        It’s a law related to processing personal data of a natural person who is a California
        resident, which also covers:
      </p>
      <ul>
        <li>every individual who is in the State for other than a temporary or transitory </li>
        <li>
          every individual who lives in the State but it outside of the State for a temporary or
          transitory purpose.
        </li>
      </ul>
      <SubTitle style={{ marginTop: '3rem' }}>
        Why you and LiveSession are bound by the CCPA?
      </SubTitle>
      <p>
        Our services are available to customers from all over the world. Our tool records the user’s
        activity on a website or in an app. This recording is called <strong>session replay</strong>
        .
      </p>
      <p>
        As our customer under the CCPA, you are considered as a <i>business</i>, while LiveSession
        is a<i>service provider</i>. CCPA applies not only to companies doing business in
        California, but also to any other businesses managing and processing personal information of
        California’s citizens.
      </p>
      <p>We give you a tool we’re responsible for. You can use it in accordance with the law.</p>
      <p>
        CCPA applies to any business including any for-profit entity that collects consumers’
        personal data, does business in California, and satisfies at least one of the following
        thresholds:
      </p>
      <ul>
        <li>Has annual gross revenues higher than $25 million;</li>
        <li>Stores personal information of 50,000 or more consumers, households, or devices;</li>
        <li>
          Earns more than half of its annual revenue from selling consumers’ personal information.
        </li>
      </ul>
      <p>
        Please note that everything you’ll read here is not a legal advice. We suggest you contact
        your legal advisor to make sure if you are bound by the CCPA.
      </p>
      <SubTitle style={{ marginTop: '3rem' }}>
        What is a session replay? How is it related to personal data?
      </SubTitle>
      <p>
        A <strong>session replay is a reconstruction of the user’s journey on a website</strong> or
        within a web application. It consists of mouse and keyboard movements, scrolls, taps, and
        clicks. Watching a session replay feels like sitting next to the user and watching them
        interact with your website.
      </p>
      <p>
        When you use LiveSession on your website, you allow the service to process user data. There
        are two main ways of processing data, <strong>passive</strong> and <strong>active</strong>.
      </p>
      <p>
        <strong>Passive processing</strong> is related to session replays stored on Google Cloud. As
        our client, you’re the only person who can access this data.
      </p>
      <p>
        <strong>Active processing</strong> involves the data you send us intentionally, such as
        company details, email addresses, and the names of your employees. You need to share this
        information to use our service.
      </p>
      <SubTitle style={{ marginTop: '3rem' }}>
        Is it possible to use LiveSession without recording any personal data?
      </SubTitle>
      <p>
        <strong>Yes, it’s possible</strong>. Here’s how it works:
      </p>
      <p>
        Passively processed data is <strong>anonymous by default</strong>. This applies to forms on
        your website, too. If you’d like to identify recorded users, you can do it with our{' '}
        <Link href="/help/user-identification-custom-properties/">custom properties feature</Link>.
        By default, we do not record any inputs that can be potentially sensitive. On the other
        hand, if personal data is included in the <strong>static content</strong> of your website,{' '}
        <Link href="/help/how-to-anonymize-sensitive-page-content/">
          <strong>you need to anonymize it yourself</strong>
        </Link>
        . Otherwise, it will be recorded.
      </p>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important tip:</strong> We suggest that you <strong>audit your website</strong>.
        Make sure that you exclude all fields that could contain sensitive information. This is a
        way to make sure that your website is CCPA-compliant.
      </div>
      <p>
        As the website owner, you decide what kind of information (and for what purposes) you’re
        going to process. Because of that, you might need to get consent from your users. It’s good
        to regulate these terms in your privacy policy and include a link to our privacy policy,
        too. LiveSession provides the session recording tool and protects your privacy. We’re also
        here to help you exercise rights related to personal data.
      </p>

      <SubTitle style={{ marginTop: '3rem' }}>What are the user rights provided by CCPA?</SubTitle>
      <p>Here are some of the user rights under the CCPA that you need to consider: </p>
      <ul>
        <li>
          <strong>The right to amend, access, and receive copies of personal data.</strong>
        </li>
      </ul>
      <p>
        Please check our <Link href="/privacy-policy/">Privacy Policy</Link> for more information
        about this right, including access to information about your personal data.
      </p>
      <ul>
        <li>
          <strong>The right to erase data.</strong>
        </li>
      </ul>
      <p>
        The user can ask you to delete their personal data. It’s also known as the right to be
        forgotten. You can erase the user’s data very easily.
      </p>
      <ul>
        <li>
          <strong>Limitations on data processing</strong>
        </li>
        <li>
          <strong>The right to object to the processing of personal data.</strong>
        </li>
        <li>
          <strong>The right to data portability</strong>
        </li>
        <li>
          <strong>the right to non-discrimination for the exercise of your privacy rights. </strong>
        </li>
      </ul>
      <p>
        If requested, it’s possible to move the user’s personal data to another place. To exercise
        these rights, please get in touch with LiveSession at:{' '}
        <Link href="/contact/">https://livesession.io/contact/</Link> <br /> The CCPA requires us to
        answer within 45 days. We’ll do our best to reply as soon as possible.
      </p>
      <SubTitle style={{ marginTop: '3rem' }}>For more details, read our:</SubTitle>
      <ul>
        <li>
          <Link href="/privacy-policy/">Privacy Policy</Link>
        </li>
        <li>
          <Link href="/terms-of-service/">Terms of Service</Link>
        </li>
        <li>
          <Link href="/data-processing-agreement/">Data Proccessing Agreement</Link>
        </li>

        <li>
          <Link href="/data-security/">Data security information</Link>
        </li>
        <li>
          <Link href="/subprocessors/">List of data subprocessors</Link>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cloud.google.com/security/compliance/"
          >
            Google standards and certificates
          </a>
        </li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/gdpr/',
    title: 'GDPR compliance',
    description: `Personal data protection and privacy are the core values of LiveSession. Here's described all you need to know about GDPR.`,
  },
];

export const frontmatter = {
  metaTitle: 'CCPA compliance',
  metaDescription:
    'Personal data protection and privacy are the core values of LiveSession. We’ve described everything you need to know about the CCPA - California Consumer Privacy Act.',
  canonical: '/help/ccpa/',
};

const Wrapped = Article(Content);
export default () => (
  <Wrapped related={related} section="Privacy" title={frontmatter.metaTitle} {...frontmatter} />
);
